<template>
  <div>
    <HeaderPanel
      title="Create Sale Order"
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row class="no-gutters">
        <b-col cols="6">
          <InputText
            textFloat="Telephone"
            placeholder="Telephone"
            type="text"
            name="telephone"
            isRequired
            v-model="form.telephone"
            :v="$v.form.telephone"
          />
        </b-col>
      </b-row>
      <b-row class="no-gutters">
        <b-col cols="6">
          <InputText
            textFloat="Invoice No"
            placeholder="Invoice No"
            type="text"
            name="invoice_no"
            isRequired
            v-model="form.invoice_no"
            :v="$v.form.invoice_no"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col> </b-col>
        <b-col class="text-right">
          <b-button
            id="upload"
            class="main-color btn-save color-btn"
            size="md"
            :disabled="isLoading"
            @click="submit"
            >Confirm</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  
  validations: {
    form: {
      telephone: { required },
      invoice_no: { required },
    },
  },
  data() {
    return {
      form: {
        invoice_no: "",
        telephone: "",
      },
      isLoading: false,
    };
  },
  created() {
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      await this.$store.dispatch("createSaleOrder", this.form);
      let data = this.$store.state.createSaleOrder.respCreate;
      if (data.result === 1) {
        this.$swal("Success!", {
          icon: "success",
        })
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
      this.isLoading = false;
      await this.$store.dispatch("setMainLoading", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-btn {
  color: #fff;
}
</style>
